$theme-colors: (
    "primary": blue,
    "dark": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
    padding-top:56px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;700;800&display=swap');


html * {
  font-family: 'Montserrat', sans-serif;
}
body {

  background: rgb(2,118,71);
  background: linear-gradient(180deg, rgba(2,118,71,1) 0%, rgba(11,32,63,1) 80%) fixed;
}
.logo {
  height: 60px;
}
h1 {
  font-size: 3rem;
  color: #fff;
  font-weight: 700;
}
p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  margin: 30px 0;
}
a {
  font-weight: 600;
  color: #fff;
}
a:hover {
  color: #93bee9;
}
footer {
  color: #93bee9;
  padding: 20px 0;
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 992px) {  
 

  footer {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
  }
}